import React from 'react'

import SEO from '~/components/00-global/util/seo'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import { Container } from 'react-bootstrap'

const NotFoundPage = () => {
  const intl = useIntl()

  return (
    <Container>
      <SEO title={intl.formatMessage({ id: '404.title' })} robots='noindex' />
      <h1>
        <FormattedMessage id='404.title' />
      </h1>
    </Container>
  )
}

export default NotFoundPage
